import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

const RootGrid = styled(Grid)({});

const CustomGridItem = styled(Grid)({
    marginTop: 15,
    marginBottom: 15,
});

const LogoLoadingWithLogo = () => {
    const tCommon = useTranslations('common');
    const [divHeight, setDivHeight] = useState(400);
    const setInitHeight = () => setDivHeight(window.innerHeight);

    useEffect(() => {
        window.addEventListener('resize', () => setInitHeight());
        if (typeof window !== 'undefined') setInitHeight();
    }, []);

    return (
        <RootGrid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            style={{ height: divHeight }}
            wrap={'nowrap'}
        >
            <CustomGridItem>
                <Typography>{tCommon('texts.incidentReporting')}</Typography>
            </CustomGridItem>

            <CustomGridItem item>
                <Image src='/images/out-of-maintenance.jpg' width={90} height={90} alt='' />
            </CustomGridItem>

            <CustomGridItem>
                <Typography>{tCommon('texts.jeffreyService')}</Typography>
            </CustomGridItem>

            <CustomGridItem style={{ marginTop: 50 }}>
                <CircularProgress />
            </CustomGridItem>
        </RootGrid>
    );
};

export default LogoLoadingWithLogo;
